import React from "react"
import "bulma/css/bulma.css"

const MapPin = ({ text }) => {
  return (
    <div style={{
      color: "white",
      fontFamily: "Poppins",
      background: "#91487E",
      padding: "15px 10px",
      display: "inline-flex",
      textAlign: "center",
      fontSize: '8px',
      alignItems: "center",
      height: "40px",
      width: "40px",
      justifyContent: "center",
      borderRadius: "100%",
      transform: "translate(-50%, -10%)",
    }}>{text}</div>
  )
}
export default MapPin
