import React from "react"
import "bulma/css/bulma.css"
import "../styles/kontakt.scss"
import _navigation from "../components/_navigation"
import logo from "../assets/logo.png"
import logoWhite from "../assets/logo-white.png"
import PhoneIcon from "@material-ui/icons/Phone"
import RoomIcon from "@material-ui/icons/Room"
import AccessTimeIcon from "@material-ui/icons/AccessTime"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import { withStyles } from "@material-ui/core/styles"
import GoogleMap from "../components/googleMapa"
import team from "../assets/team.jpg"

const styles = () => ({
  notchedOutline: {},
  focused: {
    "& $notchedOutline": {
      borderColor: "pink",
    },
  },
  input: {
    color: "#3C3C3C",
    padding: "5px",
  },
  root: {
    "&:hover": {
      backgroundColor: "#B86C90",
    },
    marginTop: "15px",
    float: "right",
    backgroundColor: "#92497E",
    color: "#FAFAFA",
  },
})

const Kontakt = props => {
  const { classes } = props

  return (
    <div>
      <_navigation />
      <section className="hero hero-promocje-background">
        <div className="hero-body has-text-centered">
          <div className="container">
            <h1 className="title promocje-tytul">Kontakt</h1>
          </div>
        </div>
      </section>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <img
          alt="zespol-laser-medica"
          src={team}
          style={{
            width: "100%",
            maxWidth: "1200px",
            height: "auto",
          }}
        ></img>
      </div>
      <p className="pytanie-header-">Masz pytanie dotyczące wykonywanych przez nas zabiegów? Skontaktuj się z nami!</p>
      <section className="container kontakt-wrapper-main">
        <form
          action={"/sukces"}
          name="form-kontakt"
          method="post"
          data-netlify-honeypot="bot-field"
          data-netlify={true}
        >
          <input type="hidden" name="form-name" value="form-kontakt" />
          <input type="hidden" name="bot-field" />
          <div className="kontakt-form-wrapper footer-wrapper">
            <div className="infobox-container infobox-kontakt">
              <img className="logo-biale-kontakt" src={logo} alt="laser-medica-logo" />
              <div>
                <div className="flex-row-center">
                  <PhoneIcon style={{ margin: "6px", color: "#4F4F4F", opacity: 0.7 }} />
                  <span style={{ color: "#4F4F4F" }}>
                    tel.{" "}
                    <a style={{ color: "#4F4F4F" }} href="tel:914334852">
                      +48 91 433 48 52
                    </a>
                  </span>
                </div>
                <div className="flex-row-center">
                  <RoomIcon style={{ margin: "6px", color: "#4F4F4F", opacity: 0.7 }} />
                  <span style={{ color: "#4F4F4F" }}>ul. Marsz. J. Piłsudskiego 21/1, 70-462 Szczecin</span>
                </div>
                <div className="flex-row-center">
                  <AccessTimeIcon style={{ margin: "6px", color: "#4F4F4F", opacity: 0.7 }} />
                  <span style={{ color: "#4F4F4F" }}>czynne: pn-pt 9.00-20.00, sob 9.00-14.00</span>
                </div>
                <div className="konto-bankowe">
                  <span style={{ color: "#4F4F4F" }}>Konto Bankowe:</span>
                  <span style={{ color: "#4F4F4F" }}>PKO BP</span>
                  <span style={{ color: "#4F4F4F" }}>89 1020 4795 0000 9502 0336 6200</span>
                </div>
              </div>
            </div>
            <div className="contact-form-container">
              <div className="form-name-email">
                <TextField
                  InputProps={{ className: classes.input }}
                  style={{ margin: "5px" }}
                  id="standard-basic"
                  type="text"
                  name="imie"
                  label="Imię"
                />
                <TextField
                  InputProps={{ className: classes.input }}
                  style={{ margin: "5px" }}
                  id="standard-basic"
                  type="email"
                  name="email"
                  label="Email"
                />
              </div>
              <div className="te">
                <TextField
                  fullWidth
                  InputProps={{ className: classes.input }}
                  id="filled-multiline-flexible"
                  multiline
                  rows={6}
                  rowsMax={12}
                  type="text"
                  name="wiadomosc"
                  label="Wiadomość"
                />
              </div>
              <div className="btn-i-rodo-wrapper">
                <Button
                  className={classes.root}
                  variant="contained"
                  size="large"
                  type="submit"
                  endIcon={<ArrowForwardIcon />}
                >
                  Wysyłam
                </Button>
                <div className="info-rodo">
                  <strong>Informujemy, że:</strong>
                  <br />
                  Administratorem danych, w rozumieniu art. 7 pkt 4 ustawy z dnia 29 sierpnia 1997 r. o ochronie danych
                  osobowych (Dz.U. z 2014 r. poz. 1182) zawartych w niniejszym oświadczeniu jest Laser-Medica Centrum
                  Dermatologii Laserowej, Estetycznej i Dermatochirurgii z siedzibą w Szczecinie, ul. Marsz. J.
                  Piłsudskiego 21/1. Zebrane dane będą przetwarzane przez Administratora wyłącznie w celach kontaktowych
                  w ramach prowadzonej działalności gospodarczej i nie będą udostępniane innym odbiorcom. Podanie danych
                  osobowych ma charakter dobrowolny, jednakże jest niezbędne do zapewnienia sprawnego przepływu
                  informacji. Przysługuje Państwu prawo dostępu do treści oraz poprawiania swoich danych.
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
      <section className="google-map-container">
        <GoogleMap />
      </section>
      <section>
        <div className="kontakt-footer-container">
          <a href="/">
            <img className="logo-kontakt-biale" src={logoWhite} alt="laser-medica" />
          </a>
        </div>
      </section>
    </div>
  )
}
export default withStyles(styles)(Kontakt)
