import React from "react";
import "bulma/css/bulma.css"
import GoogleMapReact from 'google-map-react';
import MapPin from "./mapPin"

const GoogleMap = () => {
  const defaultProps = {
    center: {
      lat: 53.433329,
      lng: 14.541916
    },
    zoom: 18
  };
  return(

    <div style={{ height: '50vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyAuRQCjh43vQ4cjwg7wLY9L3uLQSONt9BE'}}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <MapPin
          lat={53.433329}
          lng={14.541916}
          text="Laser - Medica"
        />
      </GoogleMapReact>
    </div>
  )
}
export default GoogleMap;
